import { UserFormProps } from '@modules/Users/types';
import React from 'react';
import InputMask from 'react-input-mask';
import { Alert, Input, Label, Form, FormFeedback, FormGroup, Col, Row } from 'reactstrap';

export const UserForm = ({
  loading = false,
  registrationError,
  showAlert,
  type = 'create',
  validation,
}: UserFormProps) => {
  function handleChange(e: any) {
    const value = e.target.value.replace(/\D*/g, '');
    validation.setFieldValue('cpf', value);
  }

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">{type === 'create' ? 'Cadastrado' : 'Editado'} com sucesso.</Alert>
      ) : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">CPF</Label>
        <Col lg={10}>
          <InputMask
            mask="999.999.999-99"
            id="cpf"
            name="cpf"
            onChange={handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.cpf || ''}
            disabled={type === 'edit'}
          >
            <Input
              className="form-control"
              placeholder="Digite um CPF"
              type="text"
              invalid={!!(validation.touched.cpf && validation.errors.cpf)}
            />
          </InputMask>
          {validation.touched.cpf && validation.errors.cpf ? (
            <FormFeedback type="invalid">{validation.errors.cpf}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Email</Label>
        <Col lg={10}>
          <Input
            name="email"
            className="form-control"
            placeholder="Digite um email"
            type="text"
            disabled={type === 'edit'}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.email || ''}
            invalid={!!(validation.touched.email && validation.errors.email)}
          />
          {validation.touched.email && validation.errors.email ? (
            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Nome completo</Label>
        <Col lg={10}>
          <Input
            name="name"
            type="text"
            placeholder="Digite um nome completo"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ''}
            invalid={!!(validation.touched.name && validation.errors.name)}
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Senha</Label>
        <Col lg={10}>
          <Input
            name="password"
            type="password"
            placeholder="Digite uma senha"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.password || ''}
            invalid={!!(validation.touched.password && validation.errors.password)}
          />
          {validation.touched.password && validation.errors.password ? (
            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Confirmar Senha</Label>
        <Col lg={10}>
          <Input
            name="confirmPassword"
            type="password"
            placeholder="Confirme senha"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.confirmPassword || ''}
            invalid={!!(validation.touched.confirmPassword && validation.errors.confirmPassword)}
          />
          {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
            <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <Col lg={10}>
          <button className="btn btn-primary btn-block" type="submit" disabled={loading}>
            {loading ? <>Salvando...</> : `${type === 'create' ? 'Cadastrar' : 'Editar'} Usuário`}
          </button>
        </Col>
      </Row>
    </Form>
  );
};
