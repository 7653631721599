interface SidebarItem {
  label: string;
  type?: string;
  hasArrow?: boolean;
  icon?: string;
  url?: string;
  subItems?: SidebarItem[];
}

export const sidebarItems: SidebarItem[] = [
  {
    label: 'Inicio',
    type: 'menu-title',
  },
  {
    label: 'Dashboard',
    icon: 'bx-home-circle',
    hasArrow: true,
    url: '/#',
    subItems: [
      {
        url: '/dashboard',
        label: 'Home',
      },
    ],
  },
  {
    label: 'Aprovações',
    type: 'menu-title',
  },
  {
    label: 'Transações Pendentes',
    icon: 'bx-check-circle',
    hasArrow: false,
    url: '/transactions',
  },
  {
    label: 'Retiradas',
    icon: 'bx-money',
    hasArrow: true,
    url: '/#',
    subItems: [
      {
        url: '/pending-withdrawals',
        label: 'Pendentes',
      },
      {
        url: '/confirmed-withdrawals',
        label: 'Confirmadas',
      },
    ],
  },
  {
    label: 'Administração',
    type: 'menu-title',
  },
  {
    label: 'Usuários',
    icon: 'bx-user',
    hasArrow: true,
    url: '/#',
    subItems: [
      {
        url: '/users',
        label: 'Usuários',
      },
      {
        url: '/users/create',
        label: 'Cadastrar Usuário',
      },
    ],
  },
  {
    label: 'Clientes',
    icon: 'bxs-user-rectangle',
    hasArrow: true,
    url: '/#',
    subItems: [
      {
        url: '/clients',
        label: 'Clientes',
      },
      {
        url: '/clients/create',
        label: 'Cadastrar Clientes',
      },
      {
        url: '/clients/register',
        label: 'Cadastro Integrado',
      },
    ],
  },
  {
    label: 'Acessores',
    icon: 'bx-group',
    hasArrow: true,
    url: '/#',
    subItems: [
      {
        url: '/partners',
        label: 'Acessores',
      },
      {
        url: '/partners/create',
        label: 'Cadastrar Acessor',
      },
      {
        url: '/partners/register',
        label: 'Cadastro Integrado',
      },
    ],
  },
  {
    label: 'Grupo de Rentabilidade',
    icon: 'bx-grid-alt',
    hasArrow: true,
    url: '/#',
    subItems: [
      {
        url: '/profitability-groups',
        label: 'Grupo de Rentabilidade',
      },
      {
        url: '/profitability-groups/create',
        label: 'Cadastro de Grupo de Rentabilidade',
      },
    ],
  },
  {
    label: 'Contratos',
    icon: 'bx-grid-alt',
    hasArrow: true,
    url: '/#',
    subItems: [
      {
        url: '/contracts',
        label: 'Contratos',
      },
      {
        url: '/contracts/create',
        label: 'Cadastrar de Contratos',
      },
    ],
  },
  {
    label: 'Calendário',
    icon: 'bx-calendar',
    hasArrow: true,
    url: '/#',
    subItems: [
      {
        url: '/calendar',
        label: 'Calendário',
      },
      {
        url: '/calendar/list',
        label: 'Listar datas',
      },
      {
        url: '/calendar/create',
        label: 'Cadastrar datas',
      },
    ],
  },
];
