import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Column, flexRender, HeaderGroup } from '@tanstack/react-table';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import Flatpickr from 'react-flatpickr';
import * as moment from 'moment';

interface TheadProps {
  dragActiveColumn?: number;
  dragOver?: number;
  enableFilters: boolean;
  enableDragAndDrop: boolean;
  headers: Array<HeaderGroup<unknown>>;
  onDragStart: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  setFilterTypes: (data: Record<string, any>) => void;
}

export const Thead = ({
  dragActiveColumn,
  dragOver,
  enableFilters,
  enableDragAndDrop,
  headers,
  onDragStart,
  onDragOver,
  onDragLeave,
  onDrop,
  setFilterTypes,
}: TheadProps) => (
  <thead className={classNames('table-light')}>
    {headers.map((headerGroup) => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map((header) => (
          <th
            colSpan={header.colSpan}
            key={header.id}
            className={classNames({
              'table-draggable': enableDragAndDrop,
              'table-draggable-active': dragActiveColumn === header.index,
              'table-drag-over': dragOver === header.index,
            })}
            style={{
              width: header.column.columnDef.size ? `${header.column.columnDef.size}%` : undefined,
            }}
            data-column-index={header.index}
            draggable={enableDragAndDrop}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDragEnd={onDrop}
            onDrop={onDrop}
          >
            <div
              className="d-flex"
              onClick={!enableDragAndDrop ? header.column.getToggleSortingHandler() : undefined}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
              {!enableDragAndDrop ? (
                {
                  asc: <span className="ms-2 mdi mdi-arrow-up-bold-outline"></span>,
                  desc: <span className="ms-2 mdi mdi-arrow-down-bold-outline"></span>,
                }[header.column.getIsSorted() as string] ?? null
              ) : (
                <i className="mdi mdi-drag ms-2"></i>
              )}
            </div>
            {enableFilters && <Filter setFilterTypes={setFilterTypes} column={header.column} />}
          </th>
        ))}
      </tr>
    ))}
  </thead>
);

const Filter = ({
  column,
  setFilterTypes,
}: {
  column: Column<any, any>;
  setFilterTypes: (data: Record<string, any>) => void;
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [type, setType] = useState('');
  const { disableFilter, filterType, filterOptions, disableFilterType, isJoinColumn, filterAlias } =
    (column.columnDef?.meta as Record<string, any>) ?? {};

  const handleClick = (value: string) => {
    setType(value);
    setFilterTypes((filterTypes: Record<string, any>) => ({
      ...filterTypes,
      [column.id]: {
        type: value,
        isJoinColumn: isJoinColumn ?? false,
        filterAlias,
      },
    }));
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (filterType === 'date_range') {
      setFilterTypes((filterTypes: Record<string, any>) => {
        setType('_between');

        return {
          ...filterTypes,
          [column.id]: {
            type: '_between',
            isJoinColumn,
            filterAlias,
          },
        };
      });
    }

    if (filterType === 'text' || !filterType) {
      setFilterTypes((filterTypes: Record<string, any>) => {
        if (!disableFilterType) {
          setType('_like');
        }

        return {
          ...filterTypes,
          [column.id]: {
            type: !disableFilterType ? '_like' : '',
            isJoinColumn,
            filterAlias,
          },
        };
      });
    }

    if (isJoinColumn) {
      if (!disableFilterType) {
        setType('_like');
      }

      setFilterTypes((filterTypes: Record<string, any>) => ({
        ...filterTypes,
        [column.id]: {
          type: !disableFilterType ? '_like' : '',
          isJoinColumn,
          filterAlias,
        },
      }));
    }
  }, []);

  const [alreadyLoad, setAlreadyLoad] = useState(false);
  const [rangeDate, setDateRange] = useState<any>([]);

  useEffect(() => {
    const dates = column.getFilterValue() ? (column.getFilterValue() as string).split(';') : [];

    if (filterType === 'date_range' && dates.length >= 2 && !alreadyLoad) {
      setDateRange([moment(dates[0]).format('YYYY-MM-DD'), moment(dates[1]).format('YYYY-MM-DD')]);

      setAlreadyLoad(true);
    }
  }, [column.getFilterValue()]);

  return (
    <div className="mt-2" style={{ height: 40 }}>
      {!disableFilter && (
        <div className="position-relative d-flex direction-row">
          {filterType === 'select' && (
            <Input
              type="select"
              placeholder="Filtrar..."
              value={(column.getFilterValue() as string) ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                column.setFilterValue(e.target.value);
              }}
            >
              {filterOptions?.map((option: { value: string; label: string }) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>
          )}
          {filterType === 'text' ||
            (!filterType && (
              <Input
                type="text"
                placeholder="Filtrar..."
                value={(column.getFilterValue() as string) ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  column.setFilterValue(e.target.value);
                }}
              />
            ))}
          {filterType === 'date' && (
            <Flatpickr
              className={classNames('form-control d-block')}
              placeholder="Selecione uma data"
              onChange={(e) => {
                column.setFilterValue(moment(e[0]).format('YYYY-MM-DD'));
              }}
              value={
                column.getFilterValue()
                  ? moment(column.getFilterValue() as string).format('YYYY-MM-DD')
                  : ''
              }
              options={{
                altInput: true,
                altFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
                locale: Portuguese,
              }}
            />
          )}
          {filterType === 'date_range' && (
            <Flatpickr
              className={classNames('form-control d-block')}
              placeholder="Selecione uma data"
              onChange={(e) => {
                if (e.length > 1) {
                  const date =
                    moment(e[0]).format('YYYY-MM-DD') + ';' + moment(e[1]).format('YYYY-MM-DD');
                  column.setFilterValue(date);
                }
              }}
              value={rangeDate}
              options={{
                mode: 'range',
                altInput: true,
                altFormat: 'F j, Y',
                dateFormat: 'Y-m-d',
                locale: Portuguese,
              }}
            />
          )}
          {!disableFilterType && filterType !== 'select' && (
            <div
              className="position-absolute d-flex align-items-center"
              style={{ height: 36, right: 0 }}
            >
              <Dropdown
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
                className="pe-2"
              >
                <DropdownToggle
                  color="light"
                  size="md"
                  className="pe-0 ps-0 table-component-filter"
                  style={{ background: '#1E1E1E', border: 'none' }}
                >
                  <i className="mdi mdi-filter-variant me-0"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem active={type === '_eq'} onClick={() => handleClick('_eq')}>
                    Igual a
                  </DropdownItem>
                  <DropdownItem active={type === '_like'} onClick={() => handleClick('_like')}>
                    Contém
                  </DropdownItem>
                  <DropdownItem active={type === '_gt'} onClick={() => handleClick('_gt')}>
                    Maior que
                  </DropdownItem>
                  <DropdownItem active={type === '_gte'} onClick={() => handleClick('_gte')}>
                    Maior ou igual a
                  </DropdownItem>
                  <DropdownItem active={type === '_lt'} onClick={() => handleClick('_lt')}>
                    Menor que
                  </DropdownItem>
                  <DropdownItem active={type === '_lte'} onClick={() => handleClick('_lte')}>
                    Menor ou igual a
                  </DropdownItem>
                  <DropdownItem
                    active={type === '_startwith'}
                    onClick={() => handleClick('_startswith')}
                  >
                    Começa com
                  </DropdownItem>
                  <DropdownItem
                    active={type === '_endswith'}
                    onClick={() => handleClick('_endswith')}
                  >
                    Termina com
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
