export const moneyTransform = (value: number) => {
  const brazilianReal = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return brazilianReal.format(value);
};

export const transformMoneyToNumber = (value: string) => {
  return Number(value.replace(',', '.'));
};
