import { createColumnHelper } from '@tanstack/react-table';

import moment from 'moment';
import React from 'react';
import { moneyTransform } from '@helpers/transforms/money';
import { Link } from 'react-router-dom';

export const buildColumns = (partnerID: string) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('value', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">{moneyTransform(getValue())}</h5>
      ),
      header: 'Valor',
      size: 5,
    }),
    columnHelper.accessor('expected_payment_date', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Data do pagamento',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('expiration_date', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Data de expiração',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('created_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Data de criação',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('updated_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Última atualização',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }: any) => {
        return (
          <div className="d-flex justify-content-end gap-3">
            <Link
              to={`/partners/${partnerID}/payments/${row.original.id}/files`}
              className="btn btn-outline-warning btn-sm"
            >
              <i className="mdi mdi-eye font-size-14" id="edittooltip" /> Comprovantes
            </Link>
          </div>
        );
      },
      header: '',
      enableSorting: false,
      size: 15,
      meta: {
        disableFilter: true,
      },
    }),
  ];
};
