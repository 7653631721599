import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import { useParams } from 'react-router-dom';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import { Details } from '@modules/Client/components/Details';
import Tabs from '@modules/Client/components/Tabs';
import { getByID } from '@modules/Client/services';
import ClientInfo from '@modules/Client/components/Info';

import { getAll as getAllDocuments } from '@modules/Client/submodules/Documents/services';

import ClientDocuments from '../submodules/Documents/pages';
import ClientContacts from '../submodules/Contacts/pages';
import ClientAddresses from '../submodules/Addresses/pages';
import ClientBankDatas from '../submodules/BankDatas/pages';
import ClientContracts from '../submodules/Contracts/pages';

const ContractUpdate = ({
  activePath,
  page,
  size,
  router,
  setSearchParams,
}: IWithRouter & { activePath: string }) => {
  const { clientID } = useParams();
  const [activeTab, toggleTab] = useState<string>(activePath ?? 'clients');

  const { data, isFetching } = useQuery({
    queryKey: ['client', clientID],
    queryFn: () => getByID(clientID || ''),
  });

  const { data: documents } = useQuery({
    queryKey: ['clientDocuments', data?.id, page, size],
    queryFn: () => getAllDocuments(data?.id || '', page, size),
    initialData: { items: [], totalItems: 0 },
  });

  useEffect(() => {
    if (clientID && activeTab) {
      const pathname = activeTab === 'clients' ? '' : activeTab;
      const route = `/clients/${clientID}/${pathname}`;

      if (route !== router.location.pathname) {
        router.navigate(`/clients/${clientID}/${pathname}`);
      }
    }
  }, [activeTab, clientID]);

  useEffect(() => {
    toggleTab(activePath ?? 'clients');
  }, [activePath]);

  const getPageName = {
    documents: 'Documentos',
    contacts: 'Contatos',
    addresses: 'Endereços',
    'bank-data': 'Dados Bancários',
    contracts: 'Contratos',
    default: 'Clientes',
  };

  const clientDocument = documents.items.filter((item: any) => ['CPF', 'CNPJ'].includes(item.type));

  return (
    <Page
      title="Clientes"
      breadcrumb={{
        title: 'Clientes',
        titleHref: '/clients',
        breadcrumbItem: 'Editar cliente',
      }}
    >
      <Row>
        <Col lg={12}>
          <Details
            activeTab={activeTab}
            clientName={data?.name ?? ''}
            clientCode={data?.code ?? ''}
            clientDocument={clientDocument}
          />

          <Card>
            <Tabs activeTab={activeTab} toggleTab={toggleTab} />
            <CardBody>
              <CardTitle className="mb-4">Editar {getPageName[activePath || 'default']}</CardTitle>
              <div className="mt-3">
                {isFetching && activeTab === 'clients' ? (
                  <Spinners />
                ) : (
                  <>
                    {activeTab === 'clients' && data && clientID && (
                      <ClientInfo client={data} clientID={clientID} isLoading={isFetching} />
                    )}
                    {activeTab === 'documents' && clientID && (
                      <ClientDocuments
                        page={page}
                        size={size}
                        setSearchParams={setSearchParams}
                        router={router}
                        clientID={clientID}
                        clientType={data?.type || ''}
                      />
                    )}
                    {activeTab === 'contacts' && clientID && (
                      <ClientContacts
                        page={page}
                        size={size}
                        setSearchParams={setSearchParams}
                        router={router}
                        clientID={clientID}
                      />
                    )}
                    {activeTab === 'addresses' && clientID && (
                      <ClientAddresses
                        page={page}
                        size={size}
                        setSearchParams={setSearchParams}
                        router={router}
                        clientID={clientID}
                      />
                    )}
                    {activeTab === 'bank-data' && clientID && (
                      <ClientBankDatas
                        page={page}
                        size={size}
                        setSearchParams={setSearchParams}
                        router={router}
                        clientID={clientID}
                      />
                    )}
                    {activeTab === 'contracts' && clientID && (
                      <ClientContracts
                        page={page}
                        size={size}
                        setSearchParams={setSearchParams}
                        router={router}
                        clientID={clientID}
                      />
                    )}
                  </>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(ContractUpdate);
