import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Col, Form, FormGroup, InputGroup, Label } from 'reactstrap';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import Spinners from '@component/common/Spinner';
import ExtractTable from './ExtractTable';
import { getWithExpiry, setWithExpiry } from '@helpers/localStorage';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { getAllStatements } from '@modules/Partners/services';
import { toast } from 'react-toastify';

export const PartnerExtract = ({ partnerID, partner, documents }: any) => {
  const localStorageDates = getWithExpiry(partnerID);
  const filterDates = localStorageDates ? JSON.parse(localStorageDates) : null;
  const [{ initialDate, finalDate }, setDates] = useState<Record<string, Date | undefined>>({
    initialDate: filterDates?.initialDate
      ? moment(filterDates?.initialDate).toDate()
      : moment().subtract(1, 'months').toDate(),
    finalDate: moment(filterDates?.finalDate).toDate(),
  });

  const handlePrint = () => {
    const title = document.title;

    document.title = 'nome-documento';
    window.print();

    document.title = title;
  };

  const {
    data,
    isFetching: loading,
    refetch,
  } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['extracts', partnerID],
    queryFn: () =>
      getAllStatements({
        partnerID,
        initialDate: moment(initialDate).format('YYYY-MM-DD'),
        finalDate: moment(finalDate).format('YYYY-MM-DD'),
      }),
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (initialDate && finalDate) {
      const thirtyMinutes = 1000 * 60 * 30;
      setWithExpiry(
        partnerID,
        JSON.stringify({
          initialDate: moment(initialDate).format('YYYY-MM-DD'),
          finalDate: moment(finalDate).format('YYYY-MM-DD'),
        }),
        thirtyMinutes,
      );

      refetch();
      return;
    }

    toast.error('Por favor, selecione uma data inicial e uma data final.', {
      autoClose: 5000,
      theme: 'colored',
    });
  };

  return (
    <>
      <Helmet>
        <title>Extrato do Acessor | SGI</title>
      </Helmet>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-4" row>
          <Col lg={3} md={3}>
            <InputGroup>
              <Label className="col-form-label me-2">Data inicial:</Label>
              <Flatpickr
                name="initial_date"
                className="form-control d-block"
                placeholder="Selecione a data inicial"
                onChange={(e) => {
                  setDates((state) => ({
                    initialDate: e[0],
                    finalDate: undefined,
                  }));
                }}
                options={{
                  altInput: true,
                  altFormat: 'F j, Y',
                  dateFormat: 'Y-m-d',
                  locale: Portuguese,
                }}
              />
            </InputGroup>
          </Col>
          <Col lg={3} md={3}>
            <InputGroup>
              <Label className="col-form-label me-2">Data final:</Label>
              <Flatpickr
                name="final_date"
                className="form-control d-block"
                placeholder="Selecione a data final"
                onChange={(e) => {
                  setDates((state) => ({
                    initialDate: state.initialDate,
                    finalDate: e[0],
                  }));
                }}
                options={{
                  altInput: true,
                  altFormat: 'F j, Y',
                  dateFormat: 'Y-m-d',
                  locale: Portuguese,
                  disable: [],
                }}
              />
            </InputGroup>
          </Col>
          <Col lg={4} md={4}>
            <Button type="submit" color="primary" className="btn">
              Buscar por período
            </Button>
          </Col>

          <Col md={2} className="d-flex justify-content-end">
            <Button type="submit" color="light" className="btn" onClick={handlePrint}>
              <i className="mdi mdi-file-document-outline font-size-14" id="edittooltip" />
              Imprimir
            </Button>
          </Col>
        </FormGroup>
      </Form>

      {loading ? (
        <Spinners />
      ) : (
        <ExtractTable
          extract={data}
          documents={documents}
          partner={partner}
          initialDate={initialDate}
          finalDate={finalDate}
        />
      )}
    </>
  );
};
