import React from 'react';
import InputMask from 'react-input-mask';
import { Alert, Input, Label, Form, FormFeedback, FormGroup, Row, Col } from 'reactstrap';

import { countries } from '@helpers/constants';
import { getZipcode } from '@modules/Client/submodules/Addresses/services';
import { IClientForm } from '@modules/Client/types';

const ClientAddressesForm = ({
  type = 'create',
  showAlert,
  registrationError,
  validation,
  loading,
}: IClientForm) => {
  async function handleZipcodeChange(e: any) {
    const zipcode = e.target.value?.replace(/\D/g, '');

    validation.setFieldValue('zipcode', zipcode);

    if (zipcode.length === 8) {
      const data = await getZipcode(zipcode);
      if (data) {
        validation.setValues({
          ...validation.values,
          zipcode,
          country: 'BR',
          state: data.uf,
          city: data.localidade,
          neighbourhood: data.bairro,
          complement: data.complemento,
          street: data.logradouro,
        });
      }
    }
  }

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">
          Endereço {type === 'create' ? 'cadastrado' : 'editado'} com sucesso.
        </Alert>
      ) : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">CEP</Label>
        <Col lg={10}>
          <InputMask
            mask="99999-999"
            name="zipcode"
            onChange={handleZipcodeChange}
            onBlur={validation.handleBlur}
            value={validation.values.zipcode || ''}
          >
            <Input
              type="text"
              placeholder="Digite o CEP"
              invalid={!!(validation.touched.zipcode && validation.errors.zipcode)}
            />
          </InputMask>
          {validation.touched.zipcode && validation.errors.zipcode ? (
            <FormFeedback type="invalid">{validation.errors.zipcode}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">País</Label>
        <Col lg={10}>
          <Input
            id="country"
            name="country"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.country || ''}
            invalid={!!(validation.touched.country && validation.errors.country)}
          >
            <option value="">Selecione um País</option>
            {countries.map((country: any) => (
              <option key={country.order} value={country.acronym}>
                {country.name}
              </option>
            ))}
          </Input>
          {validation.touched.country && validation.errors.country ? (
            <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Estado</Label>
        <Col lg={10}>
          <Input
            name="state"
            type="text"
            placeholder="Digite o estado"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.state || ''}
            invalid={!!(validation.touched.state && validation.errors.state)}
          />
          {validation.touched.state && validation.errors.state ? (
            <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Cidade</Label>
        <Col lg={10}>
          <Input
            name="city"
            type="text"
            placeholder="Digite a cidade"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.city || ''}
            invalid={!!(validation.touched.city && validation.errors.city)}
          />
          {validation.touched.city && validation.errors.city ? (
            <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Bairro</Label>
        <Col lg={10}>
          <Input
            name="neighbourhood"
            type="text"
            placeholder="Digite o bairro"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.neighbourhood || ''}
            invalid={!!(validation.touched.neighbourhood && validation.errors.neighbourhood)}
          />
          {validation.touched.neighbourhood && validation.errors.neighbourhood ? (
            <FormFeedback type="invalid">{validation.errors.neighbourhood}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Rua</Label>
        <Col lg={10}>
          <Input
            name="street"
            type="text"
            placeholder="Digite a rua"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.street || ''}
            invalid={!!(validation.touched.street && validation.errors.street)}
          />
          {validation.touched.street && validation.errors.street ? (
            <FormFeedback type="invalid">{validation.errors.street}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Número</Label>
        <Col lg={10}>
          <Input
            name="number"
            type="number"
            placeholder="Digite a número"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.number || ''}
            invalid={!!(validation.touched.number && validation.errors.number)}
          />
          {validation.touched.number && validation.errors.number ? (
            <FormFeedback type="invalid">{validation.errors.number}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Complemento</Label>
        <Col lg={10}>
          <Input
            name="complement"
            type="text"
            placeholder="Digite a complemento"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.complement || ''}
            invalid={!!(validation.touched.complement && validation.errors.complement)}
          />
          {validation.touched.complement && validation.errors.complement ? (
            <FormFeedback type="invalid">{validation.errors.complement}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <Col lg={10}>
          <button className="btn btn-primary btn-block" disabled={loading} type="submit">
            {loading ? <>Salvando...</> : `${type === 'create' ? 'Cadastrar' : 'Editar'} Endereço`}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default ClientAddressesForm;
