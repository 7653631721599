import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import withRouter from '@common/withRouter';
import {
  ACCOUNT_MANDATORY,
  AGENCY_MANDATORY,
  AGENCY_MAX_CHARECTERS,
  BANK_DATA_TYPE_MANDATORY,
  BANK_NUMBER_MANDATORY,
  BANK_NUMBER_MAX_CHARECTERS,
  INSTITUTION_MANDATORY,
  INSTITUTION_MAX_CHARECTERS,
  PIX_MAX_CHARECTERS,
  REDIRECT_TIMEOUT,
} from '@helpers/constants';
import { create } from '@modules/Partners/submodules/BankDatas/services';
import { IPartnerBankDataCreate } from '@modules/Partners/types';
import PartnersBankDataForm from '@modules/Partners/submodules/BankDatas/components/PartnerBankDataForm';

const PartnerBankDatasCreate = ({ router }: IWithRouter) => {
  const { partnerID } = useParams();
  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IPartnerBankDataCreate) => create(partnerID as string, payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate(`/partners/${partnerID}/bank-data`);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: '',
      agency: '',
      account: '',
      bank_number: '',
      institution: '',
      pix: '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required(BANK_DATA_TYPE_MANDATORY),
      agency: Yup.string().max(10, AGENCY_MAX_CHARECTERS).required(AGENCY_MANDATORY),
      account: Yup.string().required(ACCOUNT_MANDATORY),
      bank_number: Yup.string().max(10, BANK_NUMBER_MAX_CHARECTERS).required(BANK_NUMBER_MANDATORY),
      institution: Yup.string()
        .max(100, INSTITUTION_MAX_CHARECTERS)
        .required(INSTITUTION_MANDATORY),
      pix: Yup.string().max(80, PIX_MAX_CHARECTERS),
    }),
    onSubmit: async (payload: IPartnerBankDataCreate) => mutate(payload),
  });

  return (
    <Page
      title="Cadastrar dados bancários do acessor"
      breadcrumb={{
        title: 'Dados Bancários',
        titleHref: `/partners/${partnerID}/bank-data`,
        breadcrumbItem: 'Cadastrar Dados Bancários',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Criar novo dados bancários</CardTitle>
              <div className="p-2 mt-3">
                <PartnersBankDataForm
                  loading={isPending}
                  validation={validation}
                  registrationError={error?.message}
                  showAlert={isSuccess}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(PartnerBankDatasCreate);
