import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

import TableActions from '@common/TableActions';

export const buildColumns = (
  isAdmin: boolean,
  clientID: string,
  onDeleteModal: (data: string) => void,
) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('type', {
      header: 'Tipo de conta',
      size: 5,
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/clients/${clientID}/bank-data/${row.original.id}`} className="text-dark">
            {getValue() === 'CC' ? 'Conta Corrente' : 'Conta Poupança'}
          </Link>
        </h5>
      ),
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'CC', label: 'Conta Corrente' },
          { value: 'CP', label: 'Conta Poupança' },
        ],
      },
    }),
    columnHelper.accessor('agency', {
      header: 'Agência',
      size: 5,
      cell: ({ getValue }: any) => <p className="text-muted mb-0">{getValue()}</p>,
    }),
    columnHelper.accessor('account', {
      header: 'Número da conta',
      size: 5,
      cell: ({ getValue }: any) => <p className="text-muted mb-0">{getValue()}</p>,
    }),
    columnHelper.accessor('bank_number', {
      header: 'Número do banco',
      size: 5,
      cell: ({ getValue }: any) => <p className="text-muted mb-0">{getValue()}</p>,
    }),
    columnHelper.accessor('institution', {
      header: 'Instituição',
      size: 30,
      cell: ({ getValue }: any) => <p className="text-muted mb-0">{getValue()}</p>,
    }),
    columnHelper.accessor('pix', {
      header: 'Pix',
      size: 10,
      cell: ({ getValue }: any) => <p className="text-muted mb-0">{getValue()}</p>,
    }),
    columnHelper.accessor('actions', {
      header: 'Ação',
      enableSorting: false,
      size: 10,
      cell: ({ row }: any) => (
        <TableActions
          isAdmin={isAdmin}
          editLink={`/clients/${clientID}/bank-data/${row.original.id}`}
          itemID={row.original.id}
          onDeleteModal={onDeleteModal}
        />
      ),
      meta: {
        disableFilter: true,
      },
    }),
  ];
};
