import { IClientForm } from '@modules/Client/types';
import React from 'react';
import InputMask from 'react-input-mask';
import { Alert, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';

const PartnerDocumentsForm = ({
  type = 'create',
  showAlert,
  registrationError,
  validation,
  partnerType = 'F',
  loading,
}: IClientForm & { partnerType?: string }) => {
  function handleChange(e: any) {
    if (['RG', 'IM'].includes(validation.values.type)) {
      validation.setFieldValue('value', e.target.value);
      return;
    }

    const value = e.target.value.replace(/\D*/g, '');
    validation.setFieldValue('value', value);
  }

  function getMask(type?: string) {
    switch (type) {
      case 'CPF':
        return '999.999.999-99';
      case 'CNPJ':
        return '99.999.999/9999-99';
      case 'IE':
        return '999.999.999.999';
      default:
        return '';
    }
  }

  const documentTypes: Record<'F' | 'J', string[]> = {
    F: ['CPF', 'RG'],
    J: ['CNPJ', 'IE', 'IM'],
  };

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">
          Documento {type === 'create' ? 'cadastrado' : 'editado'} com sucesso.
        </Alert>
      ) : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Tipo de Documento</Label>
        <Col lg={10}>
          <Input
            id="type"
            name="type"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.type || ''}
            invalid={!!(validation.touched.type && validation.errors.type)}
          >
            <option value="">Selecione um tipo de documento</option>
            {documentTypes[partnerType].map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Input>
          {validation.touched.type && validation.errors.type ? (
            <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Número do documento</Label>
        <Col lg={10}>
          <InputMask
            mask={getMask(validation.values.type)}
            name="value"
            value={validation.values.value || ''}
            onChange={handleChange}
          >
            <Input
              type="text"
              placeholder="Digite o número do documento"
              invalid={!!(validation.touched.value && validation.errors.value)}
            />
          </InputMask>
          {validation.touched.value && validation.errors.value ? (
            <FormFeedback type="invalid">{validation.errors.value}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <Col lg={10}>
          <button className="btn btn-primary btn-block" disabled={loading} type="submit">
            {loading ? <>Salvando...</> : `${type === 'create' ? 'Cadastrar' : 'Editar'} Documento`}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default PartnerDocumentsForm;
