import { IForm } from '@apptypes/form';
import React from 'react';
import InputMask from 'react-input-mask';
import { Alert, Input, Label, Form, FormFeedback, FormGroup, Col, Row } from 'reactstrap';

const PartnersContactsForm = ({
  type = 'create',
  showAlert,
  registrationError,
  validation,
  loading,
}: IForm) => {
  function handleChange(e: any) {
    const value = e.target.value;
    validation.setFieldValue('value', value);
  }

  const maskTypes: Record<string, string> = {
    EMAIL: '',
    FIXO: '(99) 9999-9999',
    CELULAR: '(99) 99999-9999',
  };

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">
          Contato {type === 'create' ? 'cadastrado' : 'editado'} com sucesso.
        </Alert>
      ) : null}

      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Tipo de contato</Label>
        <Col lg={10}>
          <Input
            id="type"
            name="type"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.type || ''}
            invalid={!!(validation.touched.type && validation.errors.type)}
          >
            <option value="">Selecione um tipo de contato</option>
            <option value="CELULAR">CELULAR</option>
            <option value="FIXO">FIXO</option>
            <option value="EMAIL">EMAIL</option>
          </Input>
          {validation.touched.type && validation.errors.type ? (
            <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Telefone ou email</Label>
        <Col lg={10}>
          <InputMask
            mask={maskTypes[validation.values.type ?? 'EMAIL']}
            name="value"
            onChange={handleChange}
            value={validation.values.value || ''}
          >
            <Input
              type="tel"
              placeholder={`Digite o ${validation.values.type === 'EMAIL' ? 'email' : 'telefone'}`}
              invalid={!!(validation.touched.value && validation.errors.value)}
            />
          </InputMask>
          {validation.touched.value && validation.errors.value ? (
            <FormFeedback type="invalid">{validation.errors.value}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4 d-flex" row check inline>
        <Col
          lg={{
            size: 10,

            offset: 2,
          }}
        >
          <Input
            type="checkbox"
            id="primary_contact"
            name="primary_contact"
            className="ms-0.75"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            checked={validation.values.primary_contact || false}
          />
          <Label className="ps-2" check htmlFor="primary_contact">
            Contato primário?
          </Label>
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <Col lg={10}>
          <button className="btn btn-primary btn-block" disabled={loading} type="submit">
            {loading ? <>Salvando...</> : `${type === 'create' ? 'Cadastrar' : 'Editar'} Contato`}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default PartnersContactsForm;
