export const CLIENT_TYPE_MANDATORY = 'O tipo de cliente é obrigatório';
export const DOCUMENT_MANDATORY = 'O CNPJ é obrigatório';
export const MUNICIPAL_REGISTRATION_MANDATORY = 'Inscrição municipal é obrigatório';
export const CORPORATE_REASON_MANDATORY = 'A razão social é obrigatória';
export const STATE_REGISTRATION_MANDATORY = 'Inscrição estadual é obrigatório';
export const NAME_MANDATORY = 'O nome é obrigatório';
export const NAME_MAX_CHARACTERS = 'O nome deve ter no máximo 200 caracteres';
export const CORPORATE_REASON_MAX_CHARACTERS = 'A razão social deve ter no máximo 200 caracteres';
export const MATIRAL_STATUS_MAX_CHARACTERS = 'O estado civil deve ter no máximo 50 caracteres';
export const PROFESSION_MAX_CHARACTERS = 'A profissão deve ter no máximo 50 caracteres';
export const NATIONALITY_MAX_CHARACTERS = 'A nacionalidade deve ter no máximo 50 caracteres';
export const OBSERVATION_MAX_CHARACTERS = 'A observação deve ter no máximo 500 caracteres';
