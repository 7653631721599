import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { Alert, Badge, Table } from 'reactstrap';
import { moneyTransform } from '@helpers/transforms/money';

const ExtractTable = ({ extract, initialDate, finalDate, partner, documents }: any) => {
  const partnerDocument = documents?.items?.filter((item: any) =>
    ['CPF', 'CNPJ'].includes(item.type),
  );

  if (Object.keys(extract).length === 0) return null;

  return (
    <div className="d-flex clients-content extracts">
      {!(extract?.items?.length > 0) ? (
        <Alert color="danger">
          Registro não encontrado para o período: de {moment(initialDate).format('DD/MM/YYYY')} até{' '}
          {moment(finalDate).format('DD/MM/YYYY')}
        </Alert>
      ) : (
        <Table className="table align-middle table-nowrap">
          <tbody>
            {partner && documents && (
              <tr>
                <td colSpan={4} className="text-start">
                  <Badge pill color="primary" className="font-size-14">
                    #{partner.code}
                  </Badge>
                  <span className="font-size-14 fw-light ms-3">{partner?.name}</span>
                  {partnerDocument.length > 0 && (
                    <>
                      <span className="font-size-14 ms-3">{partnerDocument[0].type}</span>
                      <span className="font-size-14 ms-1">{partnerDocument[0].value}</span>
                    </>
                  )}
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={2} className="text-start border-0">
                {initialDate && finalDate && (
                  <p className="font-size-14 fw-normal">
                    Período:{' '}
                    <span className="fw-medium">
                      {moment(initialDate).format('DD/MM/YYYY')} até{' '}
                      {moment(finalDate).format('DD/MM/YYYY')}
                    </span>
                  </p>
                )}
              </td>
              <td colSpan={2} className="text-end">
                <p className="font-size-14">
                  Saldo inicial do período:
                  <span className="text-success fw-bold ms-1">
                    {moneyTransform(extract.initial_value)}
                  </span>
                </p>
                <p className="font-size-14">
                  Saldo final do período:
                  <span className="text-success fw-bold ms-1">
                    {moneyTransform(extract.final_value)}
                  </span>
                </p>
              </td>
            </tr>
            {extract?.items?.map((item, index: number) => (
              <tr key={index} className="border-bottom">
                <td
                  className={classnames('text-center border-end border-end-1 pt-0 pb-0', {
                    'border-top': index === 0,
                  })}
                >
                  <div>
                    <h5 className="fw-light font-size-14 mb-0">
                      {moment(item.date).format('DD/MM/YYYY')}
                    </h5>
                  </div>
                </td>
                <td
                  className={classnames('border-end border-end-1 pt-0 pb-0', {
                    'border-top': index === 0,
                  })}
                >
                  {item?.records?.map((record: any, i: number) => (
                    <div
                      className={classnames(
                        'row d-flex flex-row justify-content-between align-items-center pt-1 pb-1 border-top border-1',
                        {
                          'border-top-0': i === 0,
                        },
                      )}
                      key={i}
                    >
                      <div className="text-start col-3">
                        <p className={`fw-normal font-size-12`}>{record.contract.client.name}</p>
                      </div>
                      <div className="text-center col-6">
                        <Badge pill color="primary" className="font-size-14">
                          #{record.contract.identification_number}
                        </Badge>
                      </div>
                      <div className="text-center d-flex justify-content-center col-3">
                        <h5 className={classnames('text-success fw-medium font-size-14 mb-0')}>
                          {moneyTransform(record.value)}
                        </h5>
                      </div>
                    </div>
                  ))}
                </td>
                <td className={classnames('pt-0 pb-0', { 'border-top': index === 0 })}>
                  <div className="text-end">
                    <h5 className="fw-bold font-size-14 text-success mb-0">
                      {moneyTransform(item.final_value)}
                    </h5>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ExtractTable;
