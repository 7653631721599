import React from 'react';
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';

import { IPartnerBankDataCreate, IStep } from '@modules/Partners/types';

import {
  ACCOUNT_MANDATORY,
  AGENCY_MANDATORY,
  AGENCY_MAX_CHARECTERS,
  BANK_DATA_TYPE_MANDATORY,
  BANK_NUMBER_MANDATORY,
  BANK_NUMBER_MAX_CHARECTERS,
  INSTITUTION_MANDATORY,
  INSTITUTION_MAX_CHARECTERS,
  PIX_MAX_CHARECTERS,
} from '@helpers/constants';
import { create } from '@modules/Partners/submodules/BankDatas/services';
import { StepTable } from '../StepTable';
import PartnerBankDataForm from '@modules/Partners/submodules/BankDatas/components/PartnerBankDataForm';

const BankDataStep = ({
  data,
  partnerID,
  incrementData,
  incrementStep,
}: IStep<IPartnerBankDataCreate[]>) => {
  const { mutate, error, isPending, isSuccess, reset } = useMutation({
    mutationFn: (payload: IPartnerBankDataCreate) => create(partnerID || '', payload),
    onSuccess: (bankData: IPartnerBankDataCreate) => {
      validation.resetForm();
      reset();

      const newBankData = Array.isArray(data) ? data : [];
      incrementData([...newBankData, bankData]);
    },
  });

  const newBankData: string[][] = [];
  for (const bankDatas in data) {
    const { type, agency, account, bank_number: number, institution, pix } = data[bankDatas];
    newBankData.push([type, agency, account, number, institution, pix]);
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: '',
      agency: '',
      account: '',
      bank_number: '',
      institution: '',
      pix: '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required(BANK_DATA_TYPE_MANDATORY),
      agency: Yup.string().max(10, AGENCY_MAX_CHARECTERS).required(AGENCY_MANDATORY),
      account: Yup.string().required(ACCOUNT_MANDATORY),
      bank_number: Yup.string().max(10, BANK_NUMBER_MAX_CHARECTERS).required(BANK_NUMBER_MANDATORY),
      institution: Yup.string()
        .max(100, INSTITUTION_MAX_CHARECTERS)
        .required(INSTITUTION_MANDATORY),
      pix: Yup.string().max(80, PIX_MAX_CHARECTERS),
    }),
    onSubmit: async (payload: IPartnerBankDataCreate) => mutate(payload),
  });

  return (
    <Row>
      <Col sm={12}>
        {data && data.length > 0 && (
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Dados Bancários do acessor</CardTitle>
              <div className="p-2 mt-3">
                <StepTable
                  columns={[
                    'Tipo de conta',
                    'Agência',
                    'Número da conta',
                    'Número do Banco',
                    'Instituição',
                    'PIX',
                  ]}
                  rows={newBankData}
                />
              </div>
            </CardBody>
          </Card>
        )}

        {data && data.length < 1 && (
          <Card>
            <CardBody>
              <CardTitle className="pb-4">Adicionar dados bancários</CardTitle>

              <PartnerBankDataForm
                loading={isPending}
                validation={validation}
                registrationError={error?.message}
                showAlert={isSuccess}
              />
            </CardBody>
          </Card>
        )}

        {data && data?.length > 0 && (
          <div className="d-flex justify-content-end mb-4">
            <Button color="primary" onClick={() => incrementStep()}>
              Finalizar cadastro
              <i className="mdi mdi-chevron-right"></i>
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default BankDataStep;
