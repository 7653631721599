import React from 'react';
import { Alert, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { IForm } from '@apptypes/form';

const PartnersForm = ({
  registrationError,
  type = 'create',
  showAlert,
  validation,
  loading,
}: IForm) => (
  <Form
    className="form-horizontal"
    onSubmit={(e) => {
      e.preventDefault();
      validation.handleSubmit();
      return false;
    }}
  >
    {showAlert ? (
      <Alert color="success">{type === 'create' ? 'Cadastrado' : 'Editado'} com sucesso.</Alert>
    ) : null}

    {registrationError && registrationError ? (
      <Alert color="danger">{registrationError}</Alert>
    ) : null}

    <FormGroup className="mb-4" row>
      <Label className="col-form-label col-lg-2">Tipo do acessor</Label>
      <Col lg={10}>
        <Input
          id="client_type"
          name="type"
          className="form-control"
          type="select"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.type || ''}
          invalid={!!(validation.touched.type && validation.errors.type)}
        >
          <option value="">Selecione um tipo de cliente</option>
          <option value="F">Física</option>
          <option value="J">Jurídica</option>
        </Input>
        {validation.touched.type && validation.errors.type ? (
          <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
        ) : null}
      </Col>
    </FormGroup>

    <FormGroup className="mb-4" row>
      <Label className="col-form-label col-lg-2">Nome do Acessor</Label>
      <Col lg={10}>
        <Input
          name="name"
          type="text"
          placeholder="Digite o nome do acessor"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.name || ''}
          invalid={!!(validation.touched.name && validation.errors.name)}
        />
        {validation.touched.name && validation.errors.name ? (
          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
        ) : null}
      </Col>
    </FormGroup>

    <FormGroup className="mb-4" row>
      <Label className="col-form-label col-lg-2">Profissão</Label>
      <Col lg={10}>
        <Input
          name="profession"
          type="text"
          placeholder="Digite a profissão"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.profession || ''}
          invalid={!!(validation.touched.profession && validation.errors.profession)}
        />
        {validation.touched.profession && validation.errors.profession ? (
          <FormFeedback type="invalid">{validation.errors.profession}</FormFeedback>
        ) : null}
      </Col>
    </FormGroup>

    <FormGroup className="mb-4" row>
      <Label className="col-form-label col-lg-2">Gênero</Label>
      <Col lg={10}>
        <Input
          id="gender"
          name="gender"
          className="form-control"
          type="select"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.gender || ''}
          invalid={!!(validation.touched.gender && validation.errors.gender)}
        >
          <option value="">Selecione um Gênero</option>
          <option value="M">Masculino</option>
          <option value="F">Feminino</option>
          <option value="O">Outro</option>
        </Input>
        {validation.touched.gender && validation.errors.gender ? (
          <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
        ) : null}
      </Col>
    </FormGroup>

    <FormGroup className="mb-4" row>
      <Label className="col-form-label col-lg-2">Ativo</Label>
      <Col lg={10}>
        <Input
          id="active"
          name="active"
          className="form-control"
          type="select"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={String(validation.values.active) || ''}
          invalid={!!(validation.touched.active && validation.errors.active)}
        >
          <option value="true">Ativado</option>
          <option value="false">Desativado</option>
        </Input>
        {validation.touched.active && validation.errors.active ? (
          <FormFeedback type="invalid">{validation.errors.active}</FormFeedback>
        ) : null}
      </Col>
    </FormGroup>

    <FormGroup className="mb-4" row>
      <Label className="col-form-label col-lg-2">Observação</Label>
      <Col lg={10}>
        <Input
          name="observation"
          type="textarea"
          placeholder="Digite uma Observação"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.observation || ''}
          invalid={!!(validation.touched.observation && validation.errors.observation)}
        />
        {validation.touched.observation && validation.errors.observation ? (
          <FormFeedback type="invalid">{validation.errors.observation}</FormFeedback>
        ) : null}
      </Col>
    </FormGroup>

    <Row className="justify-content-end">
      <Col lg={10}>
        <button className="btn btn-primary btn-block" disabled={loading} type="submit">
          {loading ? <>Salvando...</> : `${type === 'create' ? 'Cadastrar' : 'Editar'} Acessor`}
        </button>
      </Col>
    </Row>
  </Form>
);

export default PartnersForm;
