import React from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';

interface TabsProps {
  activeTab: string;
  toggleTab: (tab: string) => void;
}

const Tabs = ({ activeTab, toggleTab }: TabsProps) => (
  <Nav tabs className="nav-tabs-custom justify-content-center pt-2" role="tablist">
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'details',
        })}
        onClick={() => toggleTab('details')}
      >
        Dados cadastrais
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'documents',
        })}
        onClick={() => toggleTab('documents')}
      >
        Documentos
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'contacts',
        })}
        onClick={() => toggleTab('contacts')}
      >
        Contatos
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'addresses',
        })}
        onClick={() => toggleTab('addresses')}
      >
        Endereços
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'bank-data',
        })}
        onClick={() => toggleTab('bank-data')}
      >
        Dados do Bancários
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'contracts',
        })}
        onClick={() => toggleTab('contracts')}
      >
        Contratos
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'payments',
        })}
        onClick={() => toggleTab('payments')}
      >
        Pagamentos
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'earnings',
        })}
        onClick={() => toggleTab('earnings')}
      >
        Ganhos
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === 'extract',
        })}
        onClick={() => toggleTab('extract')}
      >
        Extrato
      </NavLink>
    </NavItem>
  </Nav>
);

export default Tabs;
