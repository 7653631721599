import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { NumericFormat } from 'react-number-format';
import { useDropzone } from 'react-dropzone';

import 'flatpickr/dist/themes/material_blue.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import Flatpickr from 'react-flatpickr';

import moment from 'moment';
import Spinners from '@common/Spinner';

const TransactionsForm = ({
  type = 'create',
  showAlert,
  registrationError,
  validation,
  loading,
  totalItems,
}: any) => {
  const [differentDate, setDifferentDate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<Array<{ file: string; name: string }>>([]);

  function handleContractDate(date: any) {
    validation.setFieldValue('contract_date', moment(date[0]).toISOString());
  }

  function handleEffectiveDate(date: any) {
    validation.setFieldValue('effective_date', moment(date[0]).toISOString());
  }

  function handleTransactionDate(date: any) {
    validation.setFieldValue('transaction_date', moment(date[0]).toISOString());
  }

  function handleValueChange(data: any) {
    validation.setFieldValue('value', data.value);
  }

  useEffect(() => {
    validation.setFieldValue('receipts', files);
  }, [files]);

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        let binaryStr = reader.result;
        if (typeof binaryStr === 'string') {
          binaryStr = binaryStr.split(',')[1]; // remove the content before the comma
          setFiles((current) => {
            current.push({
              file: binaryStr as string,
              name: file.name,
            });

            return current;
          });
        }
      };

      reader.onloadstart = () => {
        setIsLoading(true);
      };

      reader.onloadend = () => {
        setIsLoading(false);
      };

      reader.readAsDataURL(file);
    });
  };

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, application/pdf',
  });

  useEffect(() => {
    validation.setFieldValue(
      'contract_date',
      moment(new Date()).subtract({ hours: 6 }).toISOString(),
    );
  }, []);

  const errorMessages = registrationError?.split(';').filter((item) => !!item) ?? [];

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {showAlert ? (
        <Alert color="success">
          Transação {type === 'create' ? 'cadastrado' : 'editado'} com sucesso.
        </Alert>
      ) : null}

      {errorMessages.map((item: string) => (
        <Alert key={item} color="danger">
          {item}
        </Alert>
      ))}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Tipo de transação</Label>
        <Col lg={10}>
          <Input
            id="type"
            name="type"
            className="form-control"
            type="select"
            disabled={type === 'edit'}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.type || ''}
            invalid={!!(validation.touched.type && validation.errors.type)}
          >
            <option value="">Selecione um tipo de contato</option>
            <option value="APORTE">Aporte</option>
            <option value="RETIRADA">Retirada</option>
          </Input>
          {validation.touched.type && validation.errors.type ? (
            <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Valor</Label>
        <Col lg={10}>
          <InputGroup>
            <NumericFormat
              id="value"
              name="value"
              className="form-control"
              decimalSeparator=","
              thousandSeparator="."
              disabled={type === 'edit'}
              placeholder="Digite um valor"
              prefix="R$ "
              value={type === 'edit' ? validation.values.value : ''}
              decimalScale={2}
              onValueChange={handleValueChange}
            />
          </InputGroup>
          {validation.touched.value && validation.errors.value ? (
            <FormFeedback type="invalid">{validation.errors.value}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Tipo de pagamento</Label>
        <Col lg={10}>
          <Input
            id="payment_type"
            name="payment_type"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.payment_type || ''}
            invalid={!!(validation.touched.payment_type && validation.errors.payment_type)}
          >
            <option value="">Selecione um tipo</option>
            <option value="DINHEIRO">DINHEIRO</option>
            <option value="PIX">PIX</option>
            <option value="TED">TED</option>
            <option value="BOLETO">BOLETO</option>
            <option value="CHEQUE">CHEQUE</option>
            <option value="A_NEGOCIAR">A NEGOCIAR</option>
          </Input>
          {validation.touched.payment_type && validation.errors.payment_type ? (
            <FormFeedback type="invalid">{validation.errors.payment_type}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      {type !== 'edit' && (
        <FormGroup className="mb-4" row>
          <Label className="col-form-label col-lg-2">Comprovante de Pagamento</Label>
          <Col lg={10}>
            <Card
              {...getRootProps()}
              style={{
                cursor: 'pointer',
                maxWidth: '100%',
                height: '200px',
                border: '2px dashed #ddd',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CardBody
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {isLoading ? (
                  <Spinners />
                ) : (
                  <>
                    <input {...getInputProps()} />
                    <i className="mdi mdi-plus-circle-outline" style={{ fontSize: '48px' }}></i>
                    {isDragActive ? (
                      <CardText>Solte os arquivos aqui ...</CardText>
                    ) : (
                      <CardText>
                        Arraste e solte alguns arquivos aqui, ou clique para selecionar arquivos
                      </CardText>
                    )}
                    <CardText>Arquivos permitidos: PDF, JPG e PNG</CardText>
                  </>
                )}
              </CardBody>
            </Card>

            <ListGroup>
              {files.map(({ name }, index) => (
                <ListGroupItem
                  key={name}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>{name}</div>
                  <Button
                    color="danger"
                    size="small"
                    onClick={() => {
                      setFiles((current) => {
                        return current.filter((item, i) => index !== i);
                      });
                    }}
                  >
                    <i className="mdi mdi-close me-0"></i>
                  </Button>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
        </FormGroup>
      )}

      {type !== 'edit' && totalItems <= 0 && (
        <FormGroup className="mb-4" row>
          <Label className="col-form-label col-lg-2">Data da assinatura do contrato</Label>
          <Col lg={10}>
            <InputGroup disabled={type === 'edit'}>
              <Flatpickr
                name="contract_date"
                className="form-control d-block"
                placeholder="Selecione uma data para assinatura do contrato"
                onChange={handleContractDate}
                value={validation.values.contract_date || new Date()}
                options={{
                  altInput: true,
                  altFormat: 'F j, Y',
                  dateFormat: 'Y-m-d',
                  locale: Portuguese,
                }}
              />
            </InputGroup>
            {validation.touched.effective_date && validation.errors.effective_date ? (
              <FormFeedback type="invalid">{validation.errors.effective_date}</FormFeedback>
            ) : null}
          </Col>
        </FormGroup>
      )}

      {type !== 'edit' && (
        <FormGroup check className="mb-4">
          <Input
            id="other_dates"
            name="other_dates"
            type="checkbox"
            onChange={() => setDifferentDate(!differentDate)}
          />
          <Label check for="other_dates">
            Informar data diferente
          </Label>
        </FormGroup>
      )}

      {(differentDate || type === 'edit') && (
        <>
          <FormGroup className="mb-4" row>
            <Label className="col-form-label col-lg-2">Data efetiva</Label>
            <Col lg={10}>
              <InputGroup disabled={type === 'edit'}>
                <Flatpickr
                  name="effective_date"
                  className="form-control d-block"
                  placeholder="Selecione uma data efetiva"
                  disabled={type === 'edit'}
                  onChange={handleEffectiveDate}
                  value={validation.values.effective_date || ''}
                  options={{
                    altInput: true,
                    altFormat: 'F j, Y',
                    dateFormat: 'Y-m-d',
                    locale: Portuguese,
                  }}
                />
              </InputGroup>
              {validation.touched.effective_date && validation.errors.effective_date ? (
                <FormFeedback type="invalid">{validation.errors.effective_date}</FormFeedback>
              ) : null}
            </Col>
          </FormGroup>

          <FormGroup className="mb-4" row>
            <Label className="col-form-label col-lg-2">
              {validation.values.type === 'APORTE' ? 'Data do aporte' : 'Data de retirada'}
            </Label>
            <Col lg={10}>
              <InputGroup disabled={type === 'edit'}>
                <Flatpickr
                  name="transaction_date"
                  className="form-control d-block"
                  placeholder="Selecione a data da transação"
                  disabled={type === 'edit'}
                  onChange={handleTransactionDate}
                  value={validation.values.transaction_date || ''}
                  options={{
                    altInput: true,
                    altFormat: 'F j, Y',
                    dateFormat: 'Y-m-d',
                    locale: Portuguese,
                    disable: [
                      function (date: any) {
                        return (
                          moment(validation.values.effective_date).diff(moment(date), 'days') < 0
                        );
                      },
                    ],
                  }}
                />
              </InputGroup>
              {validation.touched.transaction_date && validation.errors.transaction_date ? (
                <FormFeedback type="invalid">{validation.errors.transaction_date}</FormFeedback>
              ) : null}
            </Col>
          </FormGroup>
        </>
      )}

      <Row className="justify-content-end">
        <Col lg={10}>
          <button className="btn btn-primary btn-block" disabled={loading} type="submit">
            {loading ? <>Salvando...</> : `${type === 'create' ? 'Cadastrar' : 'Editar'} Transação`}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default TransactionsForm;
