import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import withRouter from '@common/withRouter';
import TableContainer from '@common/TableContainer';
import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import { buildConfirmedColumns } from '../components/Table/constants';
import { useQuery } from '@tanstack/react-query';
import { exportData, getAll } from '../services';
import { toast } from 'react-toastify';
import { ColumnFiltersState } from '@tanstack/react-table';

const ConfirmedWithdrawals = ({ page, size, setSearchParams }: IWithRouter) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { user }: any = useSelector((state: any) => state.Profile);
  const { data, error, isError, isFetching, refetch } = useQuery({
    queryKey: ['pendingWithdrawals', { page, size, filters }],
    queryFn: () => getAll(page, size, 'true', filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  if (isError) {
    toast.error(error.message, {
      autoClose: 5000,
      theme: 'colored',
    });
  }

  const handleExportData = async () => {
    const fileUrl = await exportData();

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = `withdrawals-confirmed-export.csv`;
    link.click();
  };

  return (
    <Page
      title="Retiradas pendentes"
      breadcrumb={{
        title: 'Retiradas aprovadas',
        titleHref: '/confirmed-withdrawals',
        breadcrumbItem: 'Listar retiradas confirmadas',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="confirmedWithdrawals"
                columns={buildConfirmedColumns(user.is_admin)}
                customPageSize={size}
                currentPage={page}
                data={data.items}
                loading={isFetching}
                totalItems={data.totalItems}
                customElement={
                  <Button type="button" color="primary" outline onClick={handleExportData}>
                    Exportar dados
                  </Button>
                }
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                isPagination
                isShowingPageLength
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(ConfirmedWithdrawals);
