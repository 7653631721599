import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Row, Col, CardBody, Card, CardTitle } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IProfitabilityGroupCreate } from '@modules/ProfitabilityGroup/types';
import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import {
  DEPOSIT_BUSINESS_DAYS_MANDATORY,
  DESCRIPTION_MANDATORY,
  PERCENTAGE_MANDATORY,
  PROFITABILITY_GROUP_TYPE_MANDATORY,
  WITHDRAWAL_CALENDAR_DAYS_MANDATORY,
  WITHDRAWAL_EXECUTION_DAY_MANDATORY,
} from '@modules/ProfitabilityGroup/constants';
import { ProfitabilityGroupForm } from '@modules/ProfitabilityGroup/components';
import { getByID, update } from '@modules/ProfitabilityGroup/services';

const ProfitabilityGroupUpdate = ({ router }: IWithRouter) => {
  const { profitabilityGroupID } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ['getProfitabilityGroupByID', profitabilityGroupID],
    queryFn: () => getByID(profitabilityGroupID || ''),
    retry: 1,
  });

  const { error, isSuccess, mutate } = useMutation({
    mutationFn: (payload: IProfitabilityGroupCreate) => {
      if (profitabilityGroupID) {
        return update(profitabilityGroupID, payload);
      }

      return Promise.reject();
    },
    onSuccess: () => {
      setTimeout(() => {
        router.navigate('/profitability-groups');
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      description: data?.description || '',
      percentage: data?.percentage || 0,
      deposit_business_days: data?.deposit_business_days || 0,
      withdrawal_execution_day: data?.withdrawal_execution_day || 0,
      withdrawal_calendar_days: data?.withdrawal_calendar_days || 0,
      type: data?.type || 'JS',
      active: data?.active || false,
    },
    validationSchema: Yup.object({
      description: Yup.string().required(DESCRIPTION_MANDATORY),
      percentage: Yup.string().required(PERCENTAGE_MANDATORY),
      deposit_business_days: Yup.number().required(DEPOSIT_BUSINESS_DAYS_MANDATORY),
      withdrawal_execution_day: Yup.number().required(WITHDRAWAL_EXECUTION_DAY_MANDATORY),
      withdrawal_calendar_days: Yup.number().required(WITHDRAWAL_CALENDAR_DAYS_MANDATORY),
      type: Yup.string().required(PROFITABILITY_GROUP_TYPE_MANDATORY),
      active: Yup.string(),
    }),
    onSubmit: async (payload: IProfitabilityGroupCreate) => mutate(payload),
  });

  return (
    <Page
      title="Editar Grupo de Rentabilidade"
      breadcrumb={{
        title: 'Grupos de Rentabilidade',
        titleHref: '/profitability-groups',
        breadcrumbItem: 'Editar Grupo de Rentabilidade',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Editar Grupo de Rendabilidade</CardTitle>
              <div className="p-2 mt-3">
                {isLoading ? (
                  <Spinners />
                ) : (
                  <ProfitabilityGroupForm
                    validation={validation}
                    registrationError={error?.message}
                    showAlert={isSuccess}
                    type="edit"
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(ProfitabilityGroupUpdate);
