import axios, { AxiosResponse, AxiosError } from 'axios';
import { getCookie } from '../cookies';
import { AUTH_COOKIE_NAME } from '../constants';
import { ERROR_STATUS_CODE, ERROR_STATUS_MESSAGE } from './constants';

const instance = axios.create({
  baseURL: process.env.BASE_URL,
  timeout: 30000,
  headers: {
    'content-type': 'application/json',
    authorization: getCookie(AUTH_COOKIE_NAME),
  },
});

instance.interceptors.response.use(
  (response: typeof AxiosResponse) => response,
  (error: typeof AxiosError) => {
    if (
      error?.response?.statusCode === ERROR_STATUS_CODE.UNAUTHORIZED &&
      error?.response?.data?.message === 'jwt expired'
    ) {
      window.location.href = '/logout';
      return;
    }

    if (error?.response?.data) {
      return Promise.reject(error.response.data);
    }

    return Promise.reject({
      message: 'Erro inesperado',
      statusCode: ERROR_STATUS_CODE.INTERNAL_SERVER,
      status: ERROR_STATUS_MESSAGE.INTERNAL_SERVER,
    });
  },
);

export default instance;
