interface SearchItems {
  label: string;
  url: string;
}

export const searchItems: SearchItems[] = [
  {
    label: 'Dashboard',
    url: '/dashboard',
  },
  {
    label: 'Transações Pendentes',
    url: '/transactions',
  },
  {
    label: 'Retiradas Pendentes',
    url: '/pending-withdrawals',
  },
  {
    label: 'Usuários',
    url: '/users',
  },
  {
    label: 'Cadastrar Usuários',
    url: '/users/create',
  },
  {
    label: 'Clientes',
    url: '/clients',
  },
  {
    label: 'Cadastrar Clientes',
    url: '/clients/create',
  },
  {
    label: 'Grupo de Rentabilidade',
    url: '/profitability-groups',
  },
  {
    label: 'Cadastro de Grupo de Rentabilidade',
    url: '/profitability-groups/create',
  },
  {
    label: 'Contratos',
    url: '/contracts',
  },
  {
    label: 'Cadastrar de Contratos',
    url: '/contracts/create',
  },
  {
    label: 'Calendário de Datas',
    url: '/calendar',
  },
  {
    label: 'Listar Datas',
    url: '/calendar/list',
  },
  {
    label: 'Cadastrar Datas',
    url: '/calendar/create',
  },
];
