import React from 'react';
import { Alert, Input, Label, Form, FormFeedback, FormGroup, Col, Row } from 'reactstrap';
import { countries } from '@helpers/constants';
import { IClientForm } from '@modules/Client/types';

const ClientForm = ({ registrationError, type = 'create', validation, loading }: IClientForm) => {
  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {registrationError && registrationError ? (
        <Alert color="danger">{registrationError}</Alert>
      ) : null}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Tipo de atendimento</Label>
        <Col lg={10}>
          <Input
            id="service_type"
            name="service_type"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.service_type || ''}
            invalid={!!(validation.touched.service_type && validation.errors.service_type)}
          >
            <option value="">Selecione um tipo de atendimento</option>
            <option value="NENHUM">Nenhum</option>
            <option value="IN_HOME">In Home</option>
            <option value="BALCAO">Balcão</option>
          </Input>
          {validation.touched.service_type && validation.errors.service_type ? (
            <FormFeedback type="invalid">{validation.errors.service_type}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Tipo de Cliente</Label>
        <Col lg={10}>
          <Input
            id="client_type"
            name="type"
            className="form-control"
            type="select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.type || ''}
            invalid={!!(validation.touched.type && validation.errors.type)}
          >
            <option value="">Selecione um tipo de cliente</option>
            <option value="F">Física</option>
            <option value="J">Jurídica</option>
          </Input>
          {validation.touched.type && validation.errors.type ? (
            <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">
          {validation.getFieldProps('type').value === 'F' ? 'Nome Completo' : 'Nome Fantasia'}
        </Label>
        <Col lg={10}>
          <Input
            name="name"
            type="text"
            placeholder={
              validation.getFieldProps('type').value === 'F'
                ? 'Digite o nome do cliente'
                : 'Digite um nome fantasia'
            }
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ''}
            invalid={!!(validation.touched.name && validation.errors.name)}
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      {validation.getFieldProps('type').value === 'J' && (
        <>
          <FormGroup className="mb-4" row>
            <Label className="col-form-label col-lg-2">Razão Social</Label>
            <Col lg={10}>
              <Input
                name="corporate_reason"
                type="text"
                placeholder="Digite um nome do cliente"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.corporate_reason || ''}
                invalid={
                  !!(validation.touched.corporate_reason && validation.errors.corporate_reason)
                }
              />
              {validation.touched.corporate_reason && validation.errors.corporate_reason ? (
                <FormFeedback type="invalid">{validation.errors.corporate_reason}</FormFeedback>
              ) : null}
            </Col>
          </FormGroup>
        </>
      )}

      {validation.getFieldProps('type').value === 'F' && (
        <>
          <FormGroup className="mb-4" row>
            <Label className="col-form-label col-lg-2">Estado Cívil</Label>
            <Col lg={10}>
              <Input
                id="marital_status"
                name="marital_status"
                className="form-control"
                type="select"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.marital_status || ''}
                invalid={!!(validation.touched.marital_status && validation.errors.marital_status)}
              >
                <option value="">Selecione um Estado Cívil</option>
                <option value="casado">Casado</option>
                <option value="solteiro">Solteiro</option>
                <option value="uniao_estavel">União Estável</option>
                <option value="divorciado">Divorciado</option>
                <option value="viuvo">Viuvo</option>
              </Input>
              {validation.touched.marital_status && validation.errors.marital_status ? (
                <FormFeedback type="invalid">{validation.errors.marital_status}</FormFeedback>
              ) : null}
            </Col>
          </FormGroup>

          <FormGroup className="mb-4" row>
            <Label className="col-form-label col-lg-2">Profissão</Label>
            <Col lg={10}>
              <Input
                name="profession"
                type="text"
                placeholder="Digite uma Profissão"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.profession || ''}
                invalid={!!(validation.touched.profession && validation.errors.profession)}
              />
              {validation.touched.profession && validation.errors.profession ? (
                <FormFeedback type="invalid">{validation.errors.profession}</FormFeedback>
              ) : null}
            </Col>
          </FormGroup>

          <FormGroup className="mb-4" row>
            <Label className="col-form-label col-lg-2">Nacionalidade</Label>
            <Col lg={10}>
              <Input
                id="nationality"
                name="nationality"
                className="form-control"
                type="select"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.nationality || ''}
                invalid={!!(validation.touched.nationality && validation.errors.nationality)}
              >
                <option value="">Selecione uma Nacionalidade</option>
                {countries.map((country: any) => (
                  <option key={country.order} value={country.acronym}>
                    {country.name}
                  </option>
                ))}
              </Input>
              {validation.touched.nationality && validation.errors.nationality ? (
                <FormFeedback type="invalid">{validation.errors.nationality}</FormFeedback>
              ) : null}
            </Col>
          </FormGroup>

          <FormGroup className="mb-4" row>
            <Label className="col-form-label col-lg-2">Gênero</Label>
            <Col lg={10}>
              <Input
                id="gender"
                name="gender"
                className="form-control"
                type="select"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.gender || ''}
                invalid={!!(validation.touched.gender && validation.errors.gender)}
              >
                <option value="">Selecione um Gênero</option>
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
                <option value="O">Outro</option>
              </Input>
              {validation.touched.gender && validation.errors.gender ? (
                <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
              ) : null}
            </Col>
          </FormGroup>
        </>
      )}

      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Observação</Label>
        <Col lg={10}>
          <Input
            name="observation"
            type="textarea"
            placeholder="Digite uma Observação"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.observation || ''}
            invalid={!!(validation.touched.observation && validation.errors.observation)}
          />
          {validation.touched.observation && validation.errors.observation ? (
            <FormFeedback type="invalid">{validation.errors.observation}</FormFeedback>
          ) : null}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <Col lg={10}>
          <button className="btn btn-primary btn-block" disabled={loading} type="submit">
            {loading ? <>Salvando...</> : `${type === 'create' ? 'Cadastrar' : 'Editar'} Cliente`}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default ClientForm;
