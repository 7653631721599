import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import TableContainer from '@common/TableContainer';
import withRouter from '@common/withRouter';
import { buildColumns } from '@modules/Users/components/Table/constants';
import { deleteByID, getAll } from '@modules/Users/services';
import { ColumnFiltersState } from '@tanstack/react-table';

const Users = ({ router, page, size, setSearchParams }: IWithRouter) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { user }: any = useSelector((state: any) => state.Profile);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['getAllUsers', page, size, filters],
    queryFn: () => getAll(page, size, filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const deleteMutation = useMutation({
    mutationFn: () => {
      if (deleteUserID) {
        return deleteByID(deleteUserID);
      }

      return Promise.resolve();
    },
    onSuccess: () => {
      toast.success('Usuário deletado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      setSearchParams({ page: '1' });
      setDeleteModal(false);
      refetch();
    },
  });

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteUserID, setDeleteUserID] = useState<string | undefined>();

  const handleDeleteUserModal = (userID: string) => {
    setDeleteUserID(userID);
    setDeleteModal(true);
  };

  return (
    <Page
      title="Usuários"
      breadcrumb={{
        title: 'Usuários',
        titleHref: '/users',
        breadcrumbItem: 'Listar Usuários',
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="users"
                columns={buildColumns(user?.is_admin, handleDeleteUserModal)}
                currentPage={page}
                customPageSize={size}
                data={data.items}
                loading={isFetching}
                totalItems={data.totalItems}
                deleteModal={{
                  isOpen: deleteModal,
                  loading: isFetching,
                  onDeleteClick: deleteMutation.mutate,
                  onCloseClick: () => setDeleteModal(false),
                }}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                isAddRegister
                isPagination
                isShowingPageLength
                isDelete
                handleAddRegisterClick={() => router.navigate('/users/create')}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Users);
