import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@common/Page';
import Spinners from '@common/Spinner';
import withRouter from '@common/withRouter';
import {
  ACCOUNT_MANDATORY,
  AGENCY_MANDATORY,
  AGENCY_MAX_CHARECTERS,
  BANK_DATA_TYPE_MANDATORY,
  BANK_NUMBER_MANDATORY,
  BANK_NUMBER_MAX_CHARECTERS,
  INSTITUTION_MANDATORY,
  INSTITUTION_MAX_CHARECTERS,
  PIX_MAX_CHARECTERS,
  REDIRECT_TIMEOUT,
} from '@helpers/constants';
import { getByID, update } from '@modules/Client/submodules/BankDatas/services';
import ClientBankDataForm from '@modules/Client/submodules/BankDatas/components/ClientBankDataForm';
import { IClientBankDataCreate } from '@modules/Client/types';

const ClientBankDatasUpdate = ({ router }: IWithRouter) => {
  const { clientID, bankDataID } = useParams();
  const { data, isFetching } = useQuery({
    queryKey: ['clientDocument', { bankDataID }],
    queryFn: () => getByID(bankDataID || ''),
    retry: 1,
  });

  const { mutate, error, isPending, isSuccess } = useMutation({
    mutationFn: (payload: IClientBankDataCreate) => update(bankDataID as string, payload),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate(`/clients/${clientID}/bank-data`);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      bank_data_type: data?.type || '',
      agency: data?.agency || '',
      account: data?.account || '',
      bank_number: data?.bank_number || '',
      institution: data?.institution || '',
      pix: data?.pix || '',
    },
    validationSchema: Yup.object({
      bank_data_type: Yup.string().required(BANK_DATA_TYPE_MANDATORY),
      agency: Yup.string().max(10, AGENCY_MAX_CHARECTERS).required(AGENCY_MANDATORY),
      account: Yup.string().required(ACCOUNT_MANDATORY),
      bank_number: Yup.string().max(10, BANK_NUMBER_MAX_CHARECTERS).required(BANK_NUMBER_MANDATORY),
      institution: Yup.string()
        .max(100, INSTITUTION_MAX_CHARECTERS)
        .required(INSTITUTION_MANDATORY),
      pix: Yup.string().max(80, PIX_MAX_CHARECTERS),
    }),
    onSubmit: async (payload: IClientBankDataCreate) => mutate(payload),
  });

  return (
    <Page
      title="Atualizar dados bancários do cliente"
      breadcrumb={{
        title: 'Dados Bancários',
        titleHref: `/clients/${clientID}/bank-data`,
        breadcrumbItem: 'Editar dados bancários',
      }}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Editar dados bancários</CardTitle>
              {isFetching ? (
                <Spinners />
              ) : (
                <div className="p-2 mt-3">
                  <ClientBankDataForm
                    loading={isPending}
                    validation={validation}
                    registrationError={error?.message}
                    type="edit"
                    showAlert={isSuccess}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(ClientBankDatasUpdate);
