export const orderByCustomFilters = (id: string, desc: boolean) => ({
  contract_client_name: `["contract","client","name","${desc ? 'DESC' : 'ASC'}"]`,
  contracts_transactions_client: `["contracts_transactions", "client", "name","${
    desc ? 'DESC' : 'ASC'
  }"]`,
  contracts_transactions_contract: `["contracts_transactions","contract","profitability_group","description","${
    desc ? 'DESC' : 'ASC'
  }"]`,
  profitability_group: `["profitability_group","description","${desc ? 'DESC' : 'ASC'}"]`,
  default: `["${id}","${desc ? 'DESC' : 'ASC'}"]`,
});

export const aliasToFilterTypes = (alias: string) => ({
  profitability_group: '$profitability_group.description_like$',
  contracts_transactions_contract:
    '$contracts_transactions.contract.profitability_group.description_like$',
  contract_client_name: '$contract.client.name_like$',
  default: alias,
});
