import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TableActions from '@common/TableActions';
import { Badge } from 'reactstrap';

export const buildColumns = (
  isAdmin: boolean,
  partnerID: string,
  onDeleteModal: (data: string) => void,
) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('type', {
      header: 'Tipo de contato',
      size: 10,
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/partners/${partnerID}/contacts/${row.original.id}`} className="text-dark">
            {getValue()}
          </Link>
        </h5>
      ),
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'CELULAR', label: 'CELULAR' },
          { value: 'FIXO', label: 'FIXO' },
          { value: 'EMAIL', label: 'EMAIL' },
        ],
      },
    }),
    columnHelper.accessor('value', {
      header: 'Email ou Telefone',
      size: 50,
      cell: (data: any) => <p className="text-muted mb-0">{data.getValue()}</p>,
    }),
    columnHelper.accessor('primary_contact', {
      header: 'Contato primário',
      size: 10,
      cell: ({ getValue }: any) => (
        <>
          {getValue() ? (
            <Badge color="success" className="font-size-12" pill>
              Sim
            </Badge>
          ) : (
            <Badge color="danger" className="font-size-12" pill>
              Não
            </Badge>
          )}
        </>
      ),
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'true', label: 'Sim' },
          { value: 'false', label: 'Não' },
        ],
      },
    }),
    columnHelper.accessor('created_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Data de criação',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('updated_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Última atualização',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('actions', {
      header: 'Ação',
      enableSorting: false,
      size: 10,
      cell: ({ row }: any) => (
        <TableActions
          isAdmin={isAdmin}
          editLink={`/partners/${partnerID}/contacts/${row.original.id}`}
          itemID={row.original.id}
          onDeleteModal={onDeleteModal}
        />
      ),
      meta: {
        disableFilter: true,
      },
    }),
  ];
};
